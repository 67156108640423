import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import useSeti from '@/hooks/useSeti'
import useBootstrapData from '@/hooks/useBootstrapData'
import analytics from '@/shared-utils/analytics'

const FlightsDesktop = dynamic(() => import('./Desktop'))
const FlightsMobile = dynamic(() => import('./Mobile'))

function Flights() {
  useSeti('AA_FLIGHTS_LP') // AA Test: Unconditional firing and no visual changes
  const { isMobile } = useBootstrapData()
  useEffect(() => {
    analytics.fireGA4Event({
      event: 'page_view',
      attributes: {
        page_name: 'homepage',
        page_desc: 'air'
      }
    })
  }, [])
  return isMobile ? <FlightsMobile /> : <FlightsDesktop />
}

export default Flights
