import React, { useState } from 'react'
import { GetServerSideProps } from 'next'
import { ContentfulProvider } from '@pcln/contentful-components'
import { FeatureProvider } from '@pcln/feature-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FLIGHT_PRODUCT_TYPE } from '@/constants/products'
import type { CustomContext, ContentPageProps } from '@/types'
import BANNER_TEXT_VALUES from '@/components/AppBanner/constants'
import Flight from '@/components/Flights'
import Layout from '@/components/Layout'
import TmsScript from '@/components/TmsScript/TmsScript'
import AppBanner from '@/components/AppBanner'
import DEFAULT_META_TAGS from '@/constants/meta'
import Cobrand from '@/components/Cobrand'
import Meta from '@/components/Meta'
import useCanonicalUrl from '@/hooks/useCanonicalUrl'
import getFlightDetails, {
  extractFlightDetailsQueryParams
} from '../server/utils/get-flight-details'
import generateBootstrapData from '../utils/generate-bootstrap-data'
import prePopulateFlightValues from '../server/prepop/flights'
import fetchPCLNHeaderFooterContent from '../server/utils/pcln-header-footer'
import oktaJwtVerify from '../server/utils/okta-jwt-verifier'
import getXPclnHeaders from '../server/utils/get-x-pcln-headers'
import setiService from '../server/middleware/seti'
import mapSetiResponse from '../utils/map-seti-response'
import getContentfulData from '../server/utils/get-contentful-data'
import retrieveContentfulSecrets from '../server/utils/retrieve-contentful-secrets'

function Flights({
  content,
  headerHTML,
  footerHTML,
  bootstrapData
}: ContentPageProps) {
  const { featureToggles } = bootstrapData
  const canonicalUrl = useCanonicalUrl()
  const [queryClient] = useState(() => new QueryClient())
  return (
    <>
      <Meta
        description={DEFAULT_META_TAGS.description.flights}
        pageTitle={DEFAULT_META_TAGS.title.flights}
        canonicalUrl={canonicalUrl}
      />
      <TmsScript product="flights" />
      <FeatureProvider features={featureToggles}>
        <ContentfulProvider contentful={content}>
          <QueryClientProvider client={queryClient}>
            <AppBanner content={BANNER_TEXT_VALUES[FLIGHT_PRODUCT_TYPE]} />
            <Layout headerHTML={headerHTML} footerHTML={footerHTML}>
              <Cobrand />
              <Flight />
            </Layout>
          </QueryClientProvider>
        </ContentfulProvider>
      </FeatureProvider>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<
  ContentPageProps
> = async ctx => {
  const { req, res } = ctx as unknown as CustomContext
  const bootstrapData = generateBootstrapData('flights', req, res)
  const { webstats, referral, appVersion, appName } = bootstrapData
  const pricelineSpecificHeaders = getXPclnHeaders(req.headers)
  const { clientGUID: cguid } = webstats
  const pclnApolloHeaders = {
    ...pricelineSpecificHeaders,
    'apollographql-client-name': appName,
    'apollographql-client-version': appVersion,
    'x-pcln-cguid': cguid
  }
  const { contentfulDeliverySecret, contentfulPreviewSecret } =
    retrieveContentfulSecrets()
  const rebookOrModifyQueryParams = extractFlightDetailsQueryParams(ctx.query)
  const isContentfulEnabled =
    res.locals.features?.siteExperience?.isContentfulEnabled ?? true
  const [
    flightPrePopData,
    pclnHeaderFooterContent,
    signInStatus,
    { content, experimentNames },
    flightRebookOrModifyDetails
  ] = await Promise.all([
    prePopulateFlightValues(ctx.query),
    fetchPCLNHeaderFooterContent(req, res),
    oktaJwtVerify({ authHdr: req.headers.authorization ?? '', cguid }),
    getContentfulData({
      contentTypeId: 'pageLanding',
      contentfulDeliverySecret,
      contentfulPreviewSecret,
      product: 'Flight',
      contentPreview: ctx.query['content-preview'] === 'true',
      isContentfulEnabled
    }),
    rebookOrModifyQueryParams
      ? getFlightDetails(rebookOrModifyQueryParams, pclnApolloHeaders)
      : Promise.resolve(null)
  ])
  const setiResponseEvents = await setiService(req, {
    appVersion,
    allowSetiOverrides: true,
    webstats,
    referral,
    reqQueryParams: ctx.query,
    ...(experimentNames && {
      additionalExperiments: experimentNames
    })
  })
  const prePopData = flightPrePopData ? { flights: flightPrePopData } : null

  return {
    props: {
      content,
      ...pclnHeaderFooterContent,
      bootstrapData: {
        ...bootstrapData,
        signInStatus,
        fullSETI: setiResponseEvents,
        seti: mapSetiResponse(setiResponseEvents),
        ...(flightRebookOrModifyDetails && { flightRebookOrModifyDetails }),
        ...(prePopData && { prePopData })
      }
    }
  }
}

export default Flights
